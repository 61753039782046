import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Toggle2 from "../toggle2/Toggle2";
import SelfCleanPopup from "../POPUP/SelfCleanPopup";
import TemperatureLockPopup from "../POPUP/TemperatureLockPopup";
import AsidePage from "../AsidePage";
import Headerpage from "../Headerpage";
import SchedulePopup from "../POPUP/SchedulePopup";
import ChildLockPopup from "../POPUP/ChildLockPopup";
import axios from "axios";
import mqtt from "mqtt";
import { ThermometerSnowflake, Wifi, WifiOff } from "lucide-react";

export default function Controls() {
  const [temperatureLoading, setTemperatureLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [connectStatus, setConnectStatus] = useState(null);
  const [visibleAddressId, setVisibleAddressId] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showSchedulePopup, setshowSchedulePopup] = useState(false);
  const [selectedTime, setSelectedTime] = useState({
    hour: "7",
    minute: "00",
    period: "AM",
  });
  const [selectedFrequency, setSelectedFrequency] = useState("custom");
  const [selectedDays, setSelectedDays] = useState(["T", "W", "T"]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [showTempLockPopup, setShowTempLockPopup] = useState(false);
  const [showChildLockPopup, setshowChildLockPopup] = useState(false);
  const [addressesData, setAddressesData] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const dropdownRef = useRef(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredAddresses, setFilteredAddresses] = useState(addressesData);
  var ismqtt = false;

  const increaseTemperature = (event, deviceId) => {
    setTemperatureLoading(true);
    event.stopPropagation();

    const deviceIndex = devicesData.findIndex(
      (device) => device.deviceid === deviceId
    );
    if (deviceIndex === -1) return;

    const device = devicesData[deviceIndex];

    if (device.temperature >= 30) {
      return;
    }
    setTimeout(() => {
      const updatedTemperature = parseInt(device?.temperature) + 1;

      // const updatedDevicesData = [...devicesData];
      // updatedDevicesData[deviceIndex] = {
      //   ...device,
      //   temperature: updatedTemperature,
      // };
      // setDevicesData(updatedDevicesData);

      const command = `!tem${updatedTemperature}`;

      client.publish(`${deviceId}/cmdin`, command, (err) => {
        if (err) {
          console.error(
            `Failed to publish command to device ${deviceId}:`,
            err
          );

          const revertedDevicesData = [...devicesData];
          revertedDevicesData[deviceIndex] = {
            ...device,
            temperature: device.temperature,
          };
          setDevicesData(revertedDevicesData);
        } else {
          console.log(`Published command ${command} to device ${deviceId}`);
          const updatedDevicesData = [...devicesData];
          updatedDevicesData[deviceIndex] = {
            ...device,
            temperature: updatedTemperature,
          };
          setDevicesData(updatedDevicesData);
          setTemperatureLoading(false);
        }
      });
    }, 500);
  };

  const decreaseTemperature = (event, deviceId) => {
    setTemperatureLoading(true);
    event.stopPropagation();

    const deviceIndex = devicesData.findIndex(
      (device) => device.deviceid === deviceId
    );
    if (deviceIndex === -1) return;

    const device = devicesData[deviceIndex];

    if (device.temperature <= 16) {
      return;
    }
    setTimeout(() => {
      const updatedTemperature = parseInt(device?.temperature) - 1;

      // const updatedDevicesData = [...devicesData];
      // updatedDevicesData[deviceIndex] = {
      //   ...device,
      //   temperature: updatedTemperature,
      // };
      // setDevicesData(updatedDevicesData);

      const command = `!tem${updatedTemperature}`;

      client.publish(`${deviceId}/cmdin`, command, (err) => {
        if (err) {
          console.error(
            `Failed to publish command to device ${deviceId}:`,
            err
          );

          const revertedDevicesData = [...devicesData];
          revertedDevicesData[deviceIndex] = {
            ...device,
            temperature: device.temperature,
          };
          setDevicesData(revertedDevicesData);
        } else {
          console.log(`Published command ${command} to device ${deviceId}`);
          const updatedDevicesData = [...devicesData];
          updatedDevicesData[deviceIndex] = {
            ...device,
            temperature: updatedTemperature,
          };
          setDevicesData(updatedDevicesData);
          setTemperatureLoading(false);
        }
      });
    }, 500);
  };

  const mqttConnect = (mqttOption) => {
    setConnectStatus("Connecting");
    console.log("Connecting", mqttOption.clientId);

    if (!ismqtt) {
      const mqttUrl = `${process.env.REACT_APP_MQTT_BASE_URL}:${process.env.REACT_APP_MQTT_PORT}/mqtt`;

      setClient(
        mqtt.connect(mqttUrl, {
          protocol: "wss",  // Ensure it's WebSocket Secure
          username: process.env.REACT_APP_MQTT_USERNAME,
          password: process.env.REACT_APP_MQTT_PASSWORD,
          keepalive: 90,
          clientId: mqttOption.clientId,
          rejectUnauthorized: false, // Ignore SSL errors (if necessary)
        })
      );
    } else {
      console.log("Already connecting...");
    }
    ismqtt = true;
  };

  useEffect(() => {
    mqttConnect({
      clientId: (Math.random() + 1).toString(36).substring(7),
    });
  }, []);

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        setConnectStatus("Connected");
        console.log("Connected");
        devicesData.forEach((device) => {
          client.subscribe(`${device.deviceid}/cmdout`, (err) => {
            if (err) {
              console.error(
                `Error subscribing to topic from device ${device.deviceid}/cmdout:`,
                err
              );
            } else {
              console.log(
                `Subscribed to topic from device ${device.deviceid}/cmdout`
              );
            }
          });
          client.subscribe(`${device.deviceid}/cmdin`, (err) => {
            if (err) {
              console.error(
                `Error subscribing to topic from device ${device.deviceid}/cmdin:`,
                err
              );
            } else {
              console.log(
                `Subscribed to topic from device ${device.deviceid}/cmdin`
              );
            }
          });

          client.publish(`${device.deviceid}/cmdin`, "Hello ESP");
        });
      });
      client.on("message", (topic, message) => {
        // const payload = { topic, message: message.toString() };
        console.log("This is message", message.toString());
        const deviceId = topic.split("/")[0];
        setDevicesData((prevDevices) =>
          prevDevices.map((device) =>
            device.deviceid === deviceId
              ? { ...device, isConnected: true }
              : device
          )
        );
        let powerState = null;
        let temperature = null;
        let temperatureLock;
        if (message.toString().includes("!")) {
          if (
            message.toString().includes("!on") ||
            message.toString().includes("!off")
          ) {
            powerState = message.toString().includes("!on") ? "!on" : "!off";
          }
          if (message.includes("!tem")) {
            temperature = message.toString().split("!tem")[1].slice(0, 2);
          }
          if (message.includes("!tmlock")) {
            const match = message.toString().match(/!tmlock(\d+)/);
            if (match) {
              temperatureLock = parseInt(match[1]);
            }
          }
          console.log("CALCULATED VALUES", temperatureLock);
          setDevicesData((prevDevices) =>
            prevDevices.map((device) =>
              device.deviceid === deviceId
                ? {
                  ...device,
                  power: powerState ?? device.power,
                  temperature: temperature ?? device.temperature,
                  isConnected: true,
                  temperatureLock:
                    temperatureLock ?? device.temperatureLock ?? 18.5,
                }
                : device
            )
          );
        }
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        console.log("Connection error: ", err);
        client.end();
      });
      client.on("reconnect", () => {
        setConnectStatus("Reconnecting");
        console.log("Reconnecting");
      });
    }
    console.log("DEVICES DATA", devicesData);
  }, [client, devicesData]);
  useEffect(() => {
    setFilteredAddresses(addressesData);
  }, [addressesData]);
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchText(searchValue);

    const filtered = addressesData.filter((address) =>
      address.line2.toLowerCase().includes(searchValue)
    );

    setFilteredAddresses(filtered);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDivVisibility = (id) => {
    setVisibleAddressId((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSave = () => {
    setShowPopup(false);
  };

  const handleScheduleclose = () => {
    setshowSchedulePopup(false);
  };
  const handletempclose = () => {
    setShowTempLockPopup(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };
  const handleChildcancel = () => {
    setshowChildLockPopup(false);
  };

  const toggleDropdown = (id, event) => {
    event.stopPropagation();

    if (openDropdownId === id) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(id);
      const rect = event.currentTarget.getBoundingClientRect();
      const dropdownWidth = 100; // Estimate or measure your dropdown width
      const offset = 50; // Adjust this value to move the dropdown further left

      let left = rect.left - dropdownWidth - offset;
      if (left < 0) {
        left = 0; // Prevent going off-screen on the left
      }

      setPopupPosition({
        top: rect.top + window.scrollY,
        left: left + window.scrollX,
      });
    }
  };
  const handleOptionClick = (option, deviceId) => {
    switch (option) {
      case "Self Clean":
        setShowPopup(true);
        setSelectedDeviceId(deviceId);
        break;
      case "Temperature Lock":
        setShowTempLockPopup(true); // Corrected line
        break;
      case "Schedule":
        setshowSchedulePopup(true);
        break;
      case "Child Lock":
        setshowChildLockPopup(true);
        break;

      default:
      // setGeneralPopupContent(option);
      // setShowGeneralPopup(true);
    }
    setOpenDropdownId(null); // Close the dropdown after selection
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/shipping/getaddress`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => {
        setAddressesData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/user/devices`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => {
        console.log("INSIDE API CALL");
        const updatedDevicesData = res.data.map((device) => ({
          ...device,
          mode: "cooling",
          temperature: 24,
          power: "!off",
          isConnected: false,
          temperatureLock: 16,
        }));
        setDevicesData(updatedDevicesData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDevicesForAddress = (addressId) => {
    const filteredDevices = devicesData.filter(
      (device) => device.addressid === addressId
    );

    return filteredDevices;
  };

  return (
    <div className="flex min-h-screen bg-background">
      <AsidePage />
      <main className="flex-1 p-6 overflow-y-auto ml-64">
        <Headerpage />
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search location"
            className="w-full py-4 px-16 pr-20 border placeholder:text-subtext rounded-full border-gray-300 text-base bg-background outline-primary"
            // className="w-full py-4 px-14 pr-20 border border-borderborder rounded-full bg-background outline-primary placeholder:font-extralight font-extralight text-sm appearance-none"
            value={searchText}
            onChange={handleSearch}
          />
          {/* className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none ml-2" */}
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none ml-2"
          >
            <g clip-path="url(#clip0_972_5167)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.62694 1.83301C8.38449 1.83311 7.16007 2.13034 6.05584 2.69989C4.95162 3.26945 3.99961 4.09481 3.27924 5.10712C2.55887 6.11942 2.09103 7.28933 1.91476 8.51921C1.73848 9.7491 1.85888 11.0033 2.2659 12.1772C2.67293 13.3511 3.35478 14.4106 4.25458 15.2674C5.15437 16.1242 6.246 16.7534 7.43841 17.1025C8.63082 17.4516 9.88942 17.5104 11.1092 17.2742C12.329 17.0379 13.4746 16.5134 14.4504 15.7443L17.7981 19.092C17.971 19.259 18.2025 19.3514 18.4429 19.3493C18.6832 19.3472 18.9132 19.2508 19.0831 19.0808C19.2531 18.9109 19.3495 18.681 19.3516 18.4406C19.3536 18.2003 19.2613 17.9687 19.0943 17.7958L15.7466 14.4482C16.6523 13.2992 17.2162 11.9185 17.3738 10.4641C17.5314 9.00964 17.2763 7.54019 16.6378 6.22393C15.9993 4.90766 15.0031 3.79775 13.7632 3.02122C12.5234 2.24469 11.0899 1.83291 9.62694 1.83301ZM3.66861 9.62467C3.66861 8.04443 4.29636 6.5289 5.41376 5.4115C6.53117 4.29409 8.04669 3.66634 9.62694 3.66634C11.2072 3.66634 12.7227 4.29409 13.8401 5.4115C14.9575 6.5289 15.5853 8.04443 15.5853 9.62467C15.5853 11.2049 14.9575 12.7204 13.8401 13.8379C12.7227 14.9553 11.2072 15.583 9.62694 15.583C8.04669 15.583 6.53117 14.9553 5.41376 13.8379C4.29636 12.7204 3.66861 11.2049 3.66861 9.62467Z"
                fill="#A49F9F"
              />
            </g>
            <defs>
              <clipPath id="clip0_972_5167">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-5 hover:cursor-pointer"
          onClick={handleClick}
        ></svg>
        {filteredAddresses.map((address) => (
          <div
            key={address._id}
            className="flex flex-col justify-between min-w-full border-2 rounded-xl border-borderborder text-2xl items-center mb-4"
            onClick={() => toggleDivVisibility(address._id)}
          >
            <div className="p-2 flex justify-between w-full items-center">
              <div className="p-2 flex justify-between items-center w-full">
                <h1 className="p-2 text-lg font-normal">{address.line2}</h1>
                <h1 className="p-2 text-sm font-normal text-right">
                  Devices: {getDevicesForAddress(address?._id)?.length ?? 0}
                </h1>
              </div>

              {!visibleAddressId[address._id] && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="mr-5 hover:cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              )}
              {visibleAddressId[address._id] && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="mr-5 hover:cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                  />
                </svg>
              )}
            </div>

            {visibleAddressId[address._id] && (
              <div
                className="w-full flex flex-col items-center"
                onClick={(event) => event.stopPropagation()}
              >
                {getDevicesForAddress(address._id).map((device) => (
                  <div
                    key={device.deviceid}
                    className="flex items-center justify-between border border-gray-300 rounded-lg w-[97%] mb-4 p-2"
                  >
                    <div className="flex items-center justify-between gap-3">
                      {device?.isConnected ? (
                        <Wifi size={17} color="#00a321" />
                      ) : (
                        <WifiOff size={17} color="#c70000" />
                      )}
                      <h1 className="text-center text-base font-normal ">
                        {device.deviceName}
                      </h1>
                      <div
                        className="bg-toggle rounded-xl flex items-center justify-center relative w-40 h-12 "
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ThermometerSnowflake strokeWidth={1.2} />

                        <div className="flex flex-row items-center justify-center ml-2">
                          <button
                            onClick={(e) =>
                              decreaseTemperature(e, device.deviceid)
                            }
                            disabled={
                              device?.temperature == device?.temperatureLock ||
                              temperatureLoading
                            }
                            className="w-6 h-6 flex justify-center items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke={
                                device?.temperature ==
                                  device?.temperatureLock || temperatureLoading
                                  ? "grey"
                                  : "currentColor"
                              }
                              className="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </button>
                          <span className="mx-2 text-sm font-semibold">
                            {device.temperature}°C
                          </span>{" "}
                          <button
                            onClick={(e) =>
                              increaseTemperature(e, device.deviceid)
                            }
                            disabled={
                              device?.temperature == 30 || temperatureLoading
                            }
                            className="w-6 h-6 flex justify-center items-center "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke={
                                device?.temperature == 30 || temperatureLoading
                                  ? "grey"
                                  : "currentColor"
                              }
                              className="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 rounded-lg"
                        onClick={() =>
                          handleOptionClick("Self Clean", device.deviceid)
                        }
                      >
                        <rect width="48" height="48" rx="7" fill="#E8F6DE" />
                        <path
                          d="M24 15C19.0312 15 15 19.0312 15 24C15 28.9688 19.0312 33 24 33C28.9688 33 33 28.9688 33 24C33 19.0312 28.9688 15 24 15Z"
                          stroke="#0E0E0F"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M24 18V24.75H28.5"
                          stroke="#0E0E0F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 rounded-lg"
                        onClick={() => {
                          console.log("TEMP LOCK DEVICE DATA", devicesData);
                          handleOptionClick("Temperature Lock");
                        }}
                      >
                        <rect width="48" height="48" rx="7" fill="#E8F6DE" />
                        <g clip-path="url(#clip0_981_5281)">
                          <path
                            d="M19.8966 34.5C18.7066 34.5005 17.5498 34.1194 16.6056 33.4158C15.6614 32.7121 14.9827 31.7254 14.6747 30.6086C14.3668 29.4919 14.4469 28.3076 14.9025 27.2396C15.3582 26.1716 16.164 25.2796 17.1948 24.702L17.5808 24.486V15.75C17.5787 15.2193 17.7702 14.705 18.1214 14.2986C18.4726 13.8921 18.9607 13.6197 19.499 13.5298C20.0373 13.4398 20.5911 13.5382 21.0618 13.8074C21.5326 14.0765 21.8899 14.4991 22.0703 15H19.8966V16.5H22.2124V18H19.8966V19.5H22.2124V21H19.8966V22.5H22.2124V24.486L22.5983 24.702C23.6292 25.2796 24.435 26.1716 24.8906 27.2396C25.3463 28.3076 25.4264 29.4919 25.1185 30.6086C24.8105 31.7254 24.1318 32.7121 23.1876 33.4158C22.2434 34.1194 21.0865 34.5005 19.8966 34.5ZM23.7562 23.637V15.75C23.7562 14.7554 23.3496 13.8016 22.6258 13.0983C21.9019 12.3951 20.9202 12 19.8966 12C18.8729 12 17.8912 12.3951 17.1674 13.0983C16.4436 13.8016 16.0369 14.7554 16.0369 15.75V23.637C14.7976 24.4415 13.8575 25.6119 13.3554 26.9754C12.8532 28.3389 12.8157 29.8231 13.2484 31.2089C13.681 32.5946 14.5609 33.8085 15.758 34.6711C16.9552 35.5338 18.4061 35.9995 19.8966 35.9995C21.3871 35.9995 22.838 35.5338 24.0352 34.6711C25.2323 33.8085 26.1122 32.5946 26.5448 31.2089C26.9775 29.8231 26.9399 28.3389 26.4378 26.9754C25.9357 25.6119 24.9955 24.4415 23.7562 23.637Z"
                            fill="#0E0E0F"
                          />
                          <path
                            d="M19.6992 26C20.5612 26 21.3878 26.3424 21.9973 26.9519C22.6068 27.5614 22.9492 28.388 22.9492 29.25C22.9492 30.112 22.6068 30.9386 21.9973 31.5481C21.3878 32.1576 20.5612 32.5 19.6992 32.5C18.8373 32.5 18.0106 32.1576 17.4011 31.5481C16.7916 30.9386 16.4492 30.112 16.4492 29.25C16.4492 28.388 16.7916 27.5614 17.4011 26.9519C18.0106 26.3424 18.8373 26 19.6992 26Z"
                            stroke="#0E0E0F"
                          />
                          <rect
                            x="28.3945"
                            y="21.3423"
                            width="3"
                            height="4"
                            rx="0.5"
                            stroke="#0E0E0F"
                          />
                          <rect
                            x="27.3945"
                            y="24.3423"
                            width="5"
                            height="4"
                            rx="0.5"
                            fill="#0E0E0F"
                            stroke="#0E0E0F"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_981_5281">
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                              transform="translate(12 12)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <Toggle2 deviceData={device} client={client} />
                    </div>
                    {showPopup && (
                      <SelfCleanPopup
                        client={client}
                        deviceData={device}
                        show={showPopup}
                        deviceId={selectedDeviceId}
                        onSave={handleSave}
                        onClose={() => setShowPopup(false)}
                      />
                    )}
                    {showTempLockPopup && (
                      <TemperatureLockPopup
                        deviceData={device}
                        client={client}
                        show={showTempLockPopup}
                        onSave1={handleSave}
                        handletempclose={handletempclose}
                      />
                    )}
                    {showSchedulePopup && (
                      <SchedulePopup
                        show={showSchedulePopup}
                        handleScheduleclose={handleScheduleclose}
                      />
                    )}
                    {showChildLockPopup && (
                      <ChildLockPopup
                        show={showChildLockPopup}
                        handleChildcancel={handleChildcancel}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </main>
    </div>
  );
}
