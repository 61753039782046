import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AsidePage from "../AsidePage";
import Headerpage from "../Headerpage";
import { ErrorBoundary } from "react-error-boundary";

const PastRequestPage = () => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState({});
  const token1 = localStorage.getItem("token");

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        setLoading(true);
        const response = await axios({
          url: `${process.env.REACT_APP_API_BASE_URL}/api/query/b2blogin/getdata`,
          method: "GET",
          headers: {
            "x-auth-token": token1,
          },
        });
        console.log(response.data, "Fetched data");
        setQueries(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        if (err.response && err.response.status === 404) {
          setError("No data found. Please try again later.");
        } else {
          setError("An error occurred while fetching data. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchQueries();
  }, []);

  const toggleDetails = (id) => {
    setShowDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return (
      <div className="text-center py-4 text-primary">
        There is no Service request yet
      </div>
    );
  if (queries.length === 0)
    return <div className="text-center py-4">No requests found.</div>;

  return (
    <div className="space-y-4">
      {queries.map((item) => (
        <div
          key={item._id}
          className="bg-white p-6 rounded-lg border border-borderborder shadow"
        >
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h1 className="font-light text-lg">
                Status:{" "}
                <span
                  className={`text-lg ${
                    item.status ? "text-red-600" : "text-green-600"
                  } font-medium`}
                >
                  {item.status ? "Active" : "Resolved"}
                </span>
              </h1>
              <h2 className="text-right font-normal text-servicedate text-sm">
                {formatDate(item.TimeStamp)}
              </h2>
            </div>
            <h1 className="font-normal text-optionalcolor text-lg">
              Device Name:
              <span className="text-pasttext font-medium ml-2 text-lg">
                {item.deviceid}
              </span>
            </h1>
          </div>
          <div>
            <label
              htmlFor={`issue-${item._id}`}
              className="block text-base font-normal text-optionalcolor mb-2 mt-4"
            >
              Issue:
            </label>
            <input
              type="text"
              id={`issue-${item._id}`}
              name="issue"
              value={item.subject}
              readOnly
              className="w-full px-3 py-2 border bg-#D0D0D0 outline-none rounded-md"
            />
          </div>
          <div>
            <label className="block text-base font-normal text-optionalcolor mb-2 mt-4">
              Issue Description
            </label>
            <input
              type="text"
              value={item.summery}
              readOnly
              className="w-full px-3 py-2 border bg-#D0D0D0 outline-none rounded-md mb-3"
            />
            {showDetails[item._id] && (
              <div className="mt-3">
                <h1 className="mb-2 mt-1 text-optionalcolor">
                  Name:
                  <span className="text-pasttext ml-2">
                    {item.contactperson}
                  </span>
                </h1>
                <h1 className="mb-2 text-optionalcolor">
                  Email:
                  <span className="ml-2 text-pasttext">
                    {item.contactemail}
                  </span>
                </h1>
                <h1 className="text-optionalcolor">
                  Phone:
                  <span className="text-pasttext ml-2">
                    {item.contactnumber}
                  </span>
                </h1>
              </div>
            )}
            <div className="flex justify-end">
              <button
                className="text-blue-600 underline text-sm flex items-center"
                onClick={() => toggleDetails(item._id)}
              >
                {showDetails[item._id] ? (
                  <>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                    >
                      <path
                        d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z"
                        fill="#517BF9"
                      />
                    </svg>
                    Show less
                  </>
                ) : (
                  <>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                    >
                      <path
                        d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                        fill="#517BF9"
                      />
                    </svg>
                    More details
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const NewRequestPage = ({ addresses, CustomerData }) => {
  const [formData, setFormData] = useState({
    address: "",
    deviceName: "",
    issue: "",
    description: "",
    name: "",
    email: "",
    phone: "",
  });
  const [fileUrl, setFileUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (allData) => {
    const customer_id = localStorage.getItem("customerid");
    const userid = localStorage.getItem("userid");
    try {
      // Transform the form data into the required JSON structure
      const requestData = {
        userid: userid || "withoutAppuser",
        customer_id: customer_id,
        contactperson: CustomerData.Fullname,
        contactnumber: CustomerData.mobile,
        contactemail: CustomerData.email,
        image: fileUrl,
        subject: formData.issue,
        summery: formData.description,
        type: "Query", // You might want to make this dynamic based on user input
        status: true,
        deviceid: formData.deviceName, // Assuming deviceName is used as deviceid
      };

      // Make the API call
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/query`,
        requestData
      );

      alert("Service request submitted successfully:", response.data);
      setFormData({
        address: "",
        deviceName: "",
        issue: "",
        description: "",
        name: "",
        email: "",
        phone: "",
      });
      return response.data;
    } catch (error) {
      console.error("Error submitting service request:", error);
      throw error;
    }
  };

  const handleFileUpload = async (file, setFileUrl, fileType) => {
    if (!file) {
      console.log(`No ${fileType} file provided`);
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = localStorage.getItem("authToken");

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL_Image}/api/customer/upload-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": token,
          },
        }
      );

      const { url } = response.data;

      setFileUrl(url);
      alert(`${fileType} file uploaded successfully`);
    } catch (err) {
      console.error(`${fileType} file upload error:`, err);
      if (err.response) {
        console.error("Error response:", err.response.data);
        alert(
          `${fileType} file upload failed: ${
            err.response.data.message || JSON.stringify(err.response.data)
          }`
        );
      } else if (err.request) {
        console.error("Error request:", err.request);
        alert(
          `${fileType} file upload failed: No response received from server`
        );
      } else {
        console.error("Error message:", err.message);
        alert(`${fileType} file upload failed: ${err.message}`);
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file, setFileUrl, "issue");
    }
  };

  const issuesArray = [
    "AC Not Cooling",
    "AC not getting On",
    "Gas Leakage",
    "Getting Error Code on AC",
    "Water Dripping from AC",
    "App related Issues",
    "Request for Routine service",
    "AC is making noise",
    "Remote of AC not working",
    "Outdoor Unit of AC not working",
    "Ice formation on AC",
    "Others",
  ];

  return (
    <>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="address"
              className="block text-base font-normal text-servicetext mb-1"
            >
              Location
            </label>
            <div className="relative">
              <select
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full px-3 pr-10 py-2 border border-gray-300 text-optionalcolor placeholder:font-extralight font-extralight text-sm rounded-md mt-1 mb-3 appearance-none h-[42px] outline-primary "
              >
                <option value="">Select address</option>
                {addresses.map((address) => (
                  <option key={address.id} value={address.address}>
                    {address.address}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4 pb-2">
                <svg
                  className="fill-current h-5 w-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="deviceName"
              className="block text-base font-normal text-servicetext mb-1"
            >
              Device name
            </label>
            <input
              type="text"
              id="deviceName"
              name="deviceName"
              value={formData.deviceName}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 text-gray-700 rounded-md mt-1 mb-3 h-[42px] text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="issue"
            className="block text-base font-normal text-servicetext mt-3 mb-2"
          >
            Select issue
          </label>
          <div className="relative">
            <select
              id="issue"
              name="issue"
              value={formData.issue}
              onChange={handleChange}
              className="w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md text-optionalcolor text-sm appearance-none outline-primary"
            >
              <option value="">Select an issue</option>
              {issuesArray.map((issue, index) => (
                <option key={index} value={issue}>
                  {issue}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
              <svg
                className="fill-current h-5 w-5 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="description"
            className="mt-6 mb-3 block text-base font-normal text-servicetext "
          >
            Description of issue
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows="4"
            className="mb-6 w-full px-3 py-2 border border-gray-300 rounded-md outline-primary"
          />
        </div>
        <div className="flex items-center">
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          {/* <button
            type="button"
            onClick={() => document.getElementById("fileInput").click()}
            className="text-primary bg-aside px-4 py-2 text-sm border-none border-gray-300 rounded-md"
          >
            Upload a file
          </button> 
          <h3 className="text-sm font-normal text-optionalcolor ml-2">
            (optional)
          </h3>
          */}
        </div>
        {fileUrl && (
          <div className="mt-2">
            <p className="text-sm text-gray-600">Uploaded file: {fileUrl}</p>
          </div>
        )}
      </form>
      <div className="mt-8 bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-medium mb-1">Contact details</h2>
        <p className="text-sm text-optionalcolor mb-4 font-light">
          Please enter details of the person present at the mentioned location
        </p>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="name"
              className="block text-base font-normal text-servicetext mb-2 text-"
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={CustomerData.Fullname}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md outline-primary"
              readOnly
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-base font-normal text-servicetext mb-2"
            >
              Email id
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={CustomerData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md outline-primary"
              readOnly
            />
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="phone"
            className="block text-base font-normal text-servicetext mb-2"
          >
            Phone no.
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={CustomerData.mobile}
            onChange={handleChange}
            className="w-[49%] px-3 py-2 border border-gray-300 rounded-md outline-primary"
            readOnly
          />
        </div>
      </div>
      <div className="mt-10 flex justify-center">
        <button
          onClick={handleSubmit}
          className="py-2 px-6 mr-10 text-white bg-primary rounded-lg"
        >
          Submit
        </button>
        <button
          type="button"
          className="py-2 px-6 border-2 border-primary text-primary rounded-lg"
        >
          Cancel
        </button>
      </div>
    </>
  );
};
const Service = () => {
  const [CustomerData, setCustomerData] = useState({
    Fullname: "",
    mobail: "",
    email: "",
  });
  const [activeButton, setActiveButton] = useState("new"); // 'new' or 'past'

  const [addresses, setAddresses] = useState([]);
  const newButtonRef = useRef(null);
  const pastButtonRef = useRef(null);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  useEffect(() => {
    const fetchCustomerData = async () => {
      const userid = localStorage.getItem("userid");
      const token = localStorage.getItem("token");

      if (!userid || !token) {
        console.error("Customer ID or token is missing");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/shipping/getaddress`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        const shippingAddresses = response.data.map((item) => ({
          id: item._id,
          address: `${item.line1},${item.line2}, ${item.city}, ${item.state}, ${item.pincode}`,
        }));

        setAddresses(shippingAddresses);
        console.log(shippingAddresses);
      } catch (error) {
        console.error(
          "Error fetching customer data:",
          error.response?.statusText || error.message
        );
      }
    };

    fetchCustomerData();
  }, []);
  useEffect(() => {
    const updateIndicator = () => {
      const activeRef = activeButton === "new" ? newButtonRef : pastButtonRef;
      if (activeRef.current) {
        const { offsetLeft, offsetWidth } = activeRef.current;
        setIndicatorStyle({
          left: `${offsetLeft}px`,
          width: `${offsetWidth}px`,
        });
      }
    };

    updateIndicator();
    window.addEventListener("resize", updateIndicator);
    return () => window.removeEventListener("resize", updateIndicator);
  }, [activeButton]);
  const [formData, setFormData] = useState({
    location: "",
    deviceName: "",
    issue: "",
    description: "",
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/sevicerequest/getdata`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => {
        setCustomerData(res.data);
        console.log("lll", CustomerData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ErrorFallback = ({ error }) => (
    <div className="text-center py-4 text-red-600">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
  return (
    <div className="flex min-h-screen bg-background">
      <AsidePage />
      <main className="flex-1 p-6 overflow-y-auto md:ml-64 ml-48">
        <Headerpage />
        <div className="flex mb-6 text-sm relative">
          <button
            ref={newButtonRef}
            className={`px-1 py-2 font-normal ${
              activeButton === "new" ? "text-primary" : "text-gray-500"
            }`}
            onClick={() => setActiveButton("new")}
          >
            + New Request
          </button>
          <button
            ref={pastButtonRef}
            className={`px-4 py-2 font-normal ${
              activeButton === "past" ? "text-primary" : "text-gray-500"
            }`}
            onClick={() => setActiveButton("past")}
          >
            Past Requests
          </button>
          <div
            className="absolute bottom-0 h-0.5 bg-primary transition-all duration-300 ease-in-out"
            style={indicatorStyle}
          />
        </div>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {activeButton === "new" ? (
            <NewRequestPage
              CustomerData={CustomerData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              addresses={addresses}
            />
          ) : (
            <PastRequestPage />
          )}
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default Service;
