import React, { useState, useEffect } from "react";

const Toggle2 = ({ client, deviceData }) => {
  // Initialize state based on the value prop
  const [isOn, setIsOn] = useState(deviceData?.power === "!on");

  // Effect to update the toggle state if the value prop changes
  useEffect(() => {
    setIsOn(deviceData?.power === "!on");
  }, [deviceData?.power]);

  const togglePower = async () => {
    const newState = !isOn;
    setIsOn(newState);
    const command = newState ? "!on" : "!off";
    if (client) {
      client.publish(`${deviceData?.deviceid}/cmdin`, command, (err) => {
        if (err) {
          console.error(
            `Failed to publish command to device ${deviceData?.deviceid}:`,
            err
          );
          setIsOn(!newState);
        } else {
          console.log(
            `Published command ${command} to device ${deviceData?.deviceid}`
          );
        }
      });
    } else {
      console.error("MQTT client is not connected. Command not published.");

      setIsOn(!newState);
    }
  };

  return (
    <div>
      <div
        className="bg-toggle rounded-xl flex items-center cursor-pointer relative w-20 h-12"
        onClick={togglePower}
      >
        <div
          className={`mr-2 ml-2 mt-2 absolute top-1 left-1 bg-white rounded-full shadow w-8 h-8 flex items-center justify-center transition-transform duration-300 ease-in-out ${
            isOn ? "transform translate-x-7" : ""
          }`}
        >
          <svg
            width="38"
            height="38"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="36"
              height="36"
              rx="7"
              fill={isOn ? "#FFFFFF" : "#D0F0C0"}
            />
            <g clipPath="url(#clip0_1059_1616)">
              <path
                d="M16.2808 10V19.8312H18.7192V10H16.2808ZM11.4892 12.4892C9.95099 14.0274 9 16.1528 9 18.5C9 23.1944 12.8056 27 17.5 27C22.1944 27 26 23.1944 26 18.5C26 16.1528 25.049 14.0274 23.5108 12.4892L21.7988 14.2012C22.8989 15.3014 23.5793 16.8214 23.5793 18.5C23.5793 21.8572 20.8572 24.5793 17.5 24.5793C14.1428 24.5793 11.4207 21.8572 11.4207 18.5C11.4207 16.8214 12.1011 15.3014 13.2012 14.2012L11.4892 12.4892Z"
                fill="#0E0E0F"
              />
            </g>
            <defs>
              <clipPath id="clip0_1059_1616">
                <rect
                  width="17"
                  height="17"
                  fill="white"
                  transform="translate(9 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <span
          className={`absolute text-gray-700 text-base transition-transform duration-300 ease-in-out font ${
            isOn ? "left-2" : "right-2"
          }`}
        >
          {isOn ? "On" : "Off"}
        </span>
      </div>
    </div>
  );
};

export default Toggle2;
