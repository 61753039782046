import React, { useState, useEffect } from "react";
import axios from "axios";
import { X } from "lucide-react";

const SelfCleanPopup = ({ show, deviceId, onClose, deviceData, client }) => {
  const [scheduleData, setScheduleData] = useState([]);
  const frequencyData = ["Once", "Daily", "Monday-Friday"];
  const [frequency, setFrequency] = useState("once");
  const [selfClean, setSelfClean] = useState(false);
  const [customDays, setCustomDays] = useState({
    S: false,
    M: false,
    T: true,
    W: true,
    T: false,
    F: false,
    S: false,
  });
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState({
    hour: 12,
    minute: 0,
    period: "AM",
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (date) => {
    const hours = date.getUTCHours(); // Use UTC hours
    const minutes = date.getUTCMinutes(); // Use UTC minutes
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return {
      hour: formattedHours,
      minute: formattedMinutes,
      period: ampm,
    };
  };

  const getTimeFromScheduleData = () => {
    if (!scheduleData[0]?.startTimer) return formatTime(new Date());

    const date = new Date(scheduleData[0].startTimer);
    return formatTime(date);
  };

  const time = getTimeFromScheduleData();

  const handleHourChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setSelectedTime({ ...selectedTime, hour: "" });
    } else {
      const hour = parseInt(value, 10);
      if (hour >= 1 && hour <= 12) {
        setSelectedTime({ ...selectedTime, hour });
      }
    }
  };

  const handleMinuteChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setSelectedTime({ ...selectedTime, minute: "" });
    } else {
      const minute = parseInt(value, 10);
      if (minute >= 0 && minute <= 59) {
        setSelectedTime({ ...selectedTime, minute });
      }
    }
  };

  const handlePeriodChange = (period) => {
    setSelectedTime({ ...selectedTime, period });
  };

  const handleFrequencyChange = (value) => {
    setFrequency(value);
  };

  const handleCustomDayToggle = (day) => {
    setCustomDays({ ...customDays, [day]: !customDays[day] });
  };

  const getCurrentState = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/scheduler/self_clean/${deviceData?.deviceid}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => {
        console.log("SCHEDULE STATUS", res);
        setScheduleData(res.data.data);

        setSelfClean(res.data.data.length > 0);

        if (res.data.data.length > 0) {
          const { daily, once, monToFri } = res.data.data[0];
          if (daily) setFrequency("daily");
          else if (once) setFrequency("once");
          else if (monToFri) setFrequency("monday-friday");
        }

        if (res.data.data.length > 0 && res.data.data[0].startTimer) {
          const date = new Date(res.data.data[0].startTimer);
          const formattedTime = formatTime(date);
          setSelectedTime({
            hour: formattedTime.hour,
            minute: formattedTime.minute,
            period: formattedTime.period,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTurnOnSchedule = async () => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = days[new Date().getDay()];

    const data = {
      customer_id: deviceData?.customer_id,
      deviceid: deviceData?.deviceid,
      startScheduler: true,
      endScheduler: false,
      once: frequency === "once",
      daily: frequency === "daily",
      monToFri: frequency === "monday-friday",
      schedulerType: "self_clean",
      startCommand: "!clnon",
      custom: [
        {
          day: today,
          active: true,
        },
      ],
      startTimer: new Date().toISOString(),
    };

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/scheduler/self_clean`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: data,
    })
      .then((res) => {
        console.log(res);
        getCurrentState();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateSchedule = async () => {
    if (selectedTime.hour === "" || selectedTime.minute === "") {
      alert("Please fill in both hour and minute fields.");
      return;
    }

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = days[new Date().getDay()];

    let custom = [];
    if (frequency === "once") {
      custom = [{ day: today, active: true }];
    } else if (frequency === "daily") {
      custom = days.map((day) => ({ day, active: true }));
    } else if (frequency === "monday-friday") {
      custom = days
        .filter((day) => day !== "Sunday" && day !== "Saturday")
        .map((day) => ({ day, active: true }));
    }

    const hour24 =
      selectedTime.period === "PM"
        ? selectedTime.hour === 12
          ? 12
          : selectedTime.hour + 12
        : selectedTime.hour === 12
        ? 0
        : selectedTime.hour;

    const startTimer = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        hour24,
        selectedTime.minute,
        0
      )
    );
    startTimer.setHours(hour24, selectedTime.minute, 0, 0);

    const data = {
      ...scheduleData[0],
      once: frequency === "once",
      daily: frequency === "daily",
      monToFri: frequency === "monday-friday",
      custom,
      startTimer: startTimer.toISOString(), // Send UTC time
    };

    await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/scheduler/${scheduleData[0]?._id}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: data,
    })
      .then((res) => {
        console.log("update response", res);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteSchedule = async () => {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/scheduler/self_clean/${deviceData?.deviceid}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => {
        console.log("DELETE response", res);

        if (client) {
          client.publish(`${deviceData?.deviceid}/cmdin`, "!clnoff", (err) => {
            if (err) {
              console.error(
                `Failed to publish command to device ${deviceData?.deviceid}:`,
                err
              );
            } else {
              console.log(
                `Published command clnoff to device ${deviceData?.deviceid}`
              );
            }
          });
        } else {
          console.error("MQTT client is not connected. Command not published.");
        }
        getCurrentState();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggle = () => {
    setSelfClean(!selfClean);
    if (!selfClean) {
      handleTurnOnSchedule();
    } else {
      handleDeleteSchedule();
    }
  };

  useEffect(() => {
    if (deviceData) getCurrentState();
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white p-6 rounded-lg w-1/4 mx-auto shadow-lg relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-lg font-medium mb-4">Self clean</h2>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm">Enable Self Clean</span>
          <button
            onClick={handleToggle}
            className={`w-12 h-6 flex items-center rounded-full p-1 ${
              selfClean ? "bg-primary" : "bg-gray-300"
            }`}
          >
            <div
              className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform ${
                selfClean ? "translate-x-6" : "translate-x-0"
              }`}
            ></div>
          </button>
        </div>

        <div
          className={`bg-white p-4 rounded-md mb-6 ${
            !selfClean ? "opacity-50" : ""
          }`}
        >
          <div className="flex items-center justify-center text-4xl mb-2 space-x-2">
            <input
              type="number"
              value={selectedTime.hour}
              min="1"
              max="12"
              className={`w-16 h-16 text-center text-3xl border rounded-lg ${
                !selfClean ? "bg-gray-100 cursor-not-allowed" : ""
              }`}
              onChange={handleHourChange}
              disabled={!selfClean}
            />
            <span className="text-4xl">:</span>
            <input
              type="number"
              value={selectedTime.minute}
              min="0"
              max="59"
              className={`w-16 h-16 text-center text-3xl border rounded-lg ${
                !selfClean ? "bg-gray-100 cursor-not-allowed" : ""
              }`}
              onChange={handleMinuteChange}
              disabled={!selfClean}
            />
            <div className="flex flex-col justify-center ml-4">
              {["AM", "PM"].map((period, index) => (
                <button
                  key={period}
                  className={`px-4 py-1 text-sm ${
                    selectedTime.period === period
                      ? "bg-primary text-white"
                      : "bg-selfcleantogglebutton"
                  } rounded-md ${index === 0 ? "rounded-b-sm" : ""} 
                  ${index === 1 ? "rounded-t-sm" : ""} border-transparent ${
                    !selfClean ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => handlePeriodChange(period)}
                  disabled={!selfClean}
                >
                  {period}
                </button>
              ))}
            </div>
          </div>
          <div className="flex justify-center text-xs text-gray-600">
            <span className="mr-16">Hour</span>
            <span className="mr-20">Minute</span>
          </div>
        </div>

        <div className={`mb-6 ${!selfClean ? "opacity-50" : ""}`}>
          <h3 className="text-sm font-medium mb-2">Frequency</h3>
          {frequencyData.map((option) => (
            <div
              key={option}
              className="flex items-center justify-between mb-2"
            >
              <span className="text-sm">{option}</span>
              <input
                type="radio"
                className={`form-radio w-5 h-5 ${
                  frequency === option.toLowerCase()
                    ? "bg-primary"
                    : "border-gray-50"
                }`}
                style={{
                  accentColor:
                    frequency === option.toLowerCase() ? "#A14996" : "#A14996",
                }}
                value={option.toLowerCase()}
                checked={frequency === option.toLowerCase()}
                onChange={() => handleFrequencyChange(option.toLowerCase())}
                disabled={!selfClean}
              />
            </div>
          ))}
        </div>

        {frequency === "custom" && (
          <div
            className={`flex justify-between mb-6 ${
              !selfClean ? "opacity-50" : ""
            }`}
          >
            {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
              <button
                key={day}
                className={`w-8 h-8 text-sm ${
                  customDays[day]
                    ? "bg-selfcleancolorpop text-primary"
                    : "bg-white"
                } ${!selfClean ? "cursor-not-allowed" : ""}`}
                onClick={() => handleCustomDayToggle(day)}
                disabled={!selfClean}
              >
                {day}
              </button>
            ))}
          </div>
        )}

        <button
          className={`w-full bg-primary text-white py-2 rounded-md text-base ${
            !selfClean ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!selfClean}
          onClick={handleUpdateSchedule}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default SelfCleanPopup;
