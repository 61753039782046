import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import axios from "axios";
import logo from "../assets/logo.png";
import loginImage from "../assets/login image.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isEmailRequested, setIsEmailRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const navigate = useNavigate();

  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const generateOtp = async (e) => {
    setIsLoading(true); // Set loading to true
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/send-otp`,
        { email: email }
      )
      .then((response) => {
        console.log(response.data);
        setIsEmailRequested(true);
      })
      .catch((error) => {
        console.error("Error during login:", error);
        alert("An error occurred. Please try again later.");
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after API call completes
      });
  };

  const validateOtp = async (e) => {
    setIsLoading(true); // Set loading to true
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/validate-otp`,
        { email: email, otp: otp }
      )
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userid", response.data.userid);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("userrole", response.data.userrole);
        setIsEmailRequested(false);
        navigate("/Homepage");
      })
      .catch((error) => {
        console.error("Error during login:", error);
        alert("An error occurred. Please try again later.");
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after API call completes
      });
  };

  return (
    <div className="min-h-screen flex bg-gray-100">
      <div className="w-1/2 flex flex-col items-center justify-center p-8">
        <div className="bg-white w-full max-w-lg rounded-md shadow-md p-12">
          <h1 className="text-3xl text-center mb-8">Sign In</h1>
          <div>
            {isEmailRequested ? (
              <p className="mb-3 font-normal text-xs text-gray-500 pb-2 text-center">
                The verification code has been sent to {email}.
              </p>
            ) : null}
            {!isEmailRequested ? (
              <input
                type="string"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your Email Id"
                required
              />
            ) : (
              <input
                type="string"
                id="otp"
                name="OTP"
                value={otp}
                onChange={handleOTPChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter the OTP"
                required
              />
            )}
          </div>
          <div className="flex justify-center mt-6">
            <button
              type="submit"
              onClick={isEmailRequested ? validateOtp : generateOtp}
              disabled={isLoading} // Disable button when loading
              className={`py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </div>
              ) : (
                <>{!isEmailRequested ? "Generate OTP" : "Validate OTP"}</>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="w-1/2 flex flex-col items-center justify-start p-8 relative">
        <div className="self-end">
          <img src={logo} alt="Logo" className="w-24 h-auto" />
        </div>

        <div className="w-full flex items-center justify-center">
          <img
            src={loginImage}
            alt="Side Image"
            className="w-3/4 h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
