import React, { useState } from "react";
import { X } from "lucide-react";

const TemperatureLockPopup = ({ handletempclose, deviceData, client }) => {
  const [temperature, setTemperature] = useState(deviceData?.temperatureLock);

  const handleSave = () => {
    const command = `!tmlock${temperature}`;
    if (client) {
      client.publish(`${deviceData?.deviceid}/cmdin`, command, (err) => {
        if (err) {
          console.error(
            `Failed to publish command to device ${deviceData?.deviceid}:`,
            err
          );
        } else {
          console.log(
            `Published command ${command} to device ${deviceData?.deviceid}`
          );
        }
      });
    } else {
      console.error("MQTT client is not connected. Command not published.");
    }
    handletempclose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg p-6 w-80 shadow-tempside">
        <div className="flex justify-between">
          <h2 className="text-lg font-medium">Temperature Lock</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={handletempclose}
          >
            <X size={20} />
          </button>
        </div>
        <div className="flex items-center justify-between mb-4 mt-4">
          <button
            className={`bg-white border-2 text-4xl border-slate-100 w-10 h-10 flex items-center justify-center shadow-2xl ${
              temperature === 16 ? "text-gray-400" : "text-black"
            }`}
            onClick={() => setTemperature((prev) => Math.max(prev - 1, 0))}
            disabled={temperature === 16}
          >
            -
          </button>
          <span className="text-3xl font-bold text-primary bg-white shadow-custom-tempside w-32 h-32 flex items-center justify-center rounded-full">
            {temperature}°C
          </span>
          <button
            className={`pb-1 bg-white border-2 text-4xl border-slate-100 w-10 h-10 flex items-center justify-center shadow-2xl ${
              temperature === 30 ? "text-gray-400" : "text-black"
            }`}
            onClick={() => setTemperature((prev) => prev + 1)}
            disabled={temperature === 30}
          >
            +
          </button>
        </div>
        <div className="flex items-center border mb-8 mt-16 border-templocktext px-2 rounded-xl">
          <svg
            width="86"
            height="86"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5Z"
              stroke="#828282"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18 12H18.015V12.015H18V12Z"
              stroke="#828282"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M18 18V24"
              stroke="#828282"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="text-sm font-normal text-subtext rounded-2xl ml-4">
            Once temperature lock is enabled, no one can go below the set
            temperature.
          </p>
        </div>

        <button
          className="w-full px-4 py-2 bg-primary text-white rounded-lg text-base"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default TemperatureLockPopup;
