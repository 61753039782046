import React, { useState, useEffect } from "react";
import axios from "axios";

const AddAddressPage = ({ onAddressesSelected }) => {
  const [searchLocation, setSearchLocation] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [checkedAddresses, setCheckedAddresses] = useState({});
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  useEffect(() => {
    const fetchCustomerData = async () => {
      const userid = localStorage.getItem("userid");
      const token = localStorage.getItem("token");

      if (!userid || !token) {
        console.error("Customer ID or token is missing");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/shipping/getaddress`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        const shippingAddresses = response.data.map((item) => ({
          id: item._id,
          address: `${item.line1}, ${item.line2}, ${item.city}, ${item.state}, ${item.pincode}`,
        }));

        setAddresses(shippingAddresses);
      } catch (error) {
        console.error(
          "Error fetching customer data:",
          error.response?.statusText || error.message
        );
      }
    };

    fetchCustomerData();
    
  }, []);

  useEffect(() => {
    if (addresses.length > 0) {
      const initialCheckedState = addresses.reduce((acc, address) => {
        acc[address.id] = true; // Set all addresses to true by default
        return acc;
      }, {});
      setCheckedAddresses(initialCheckedState);
    }
  }, [addresses]);

  const handleSearch = (event) => {
    setSearchLocation(event.target.value);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newCheckedAddresses = addresses.reduce((acc, address) => {
      acc[address.id] = isChecked;
      return acc;
    }, {});
    setCheckedAddresses(newCheckedAddresses);
  };

  const handleCheckboxChange = (id) => {
    setCheckedAddresses((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const filteredAddresses = addresses.filter((address) =>
    address.address.toLowerCase().includes(searchLocation.toLowerCase())
  );

  const handleDone = () => {
    const selectedAddresses = addresses.filter(
      (address) => checkedAddresses[address.id]
    );
    console.log("Selected addresses:", selectedAddresses);

    const selectedAddressIds = selectedAddresses.map((address) => address.id);
    onAddressesSelected(selectedAddressIds);

    setShowSelectedOnly((prev) => !prev);
  };

  const displayedAddresses = showSelectedOnly
    ? filteredAddresses.filter((address) => checkedAddresses[address.id])
    : filteredAddresses;

  return (
    <div>
      <h1 className="text-lg mb-4 text-addaddressheeading">Add Address</h1>
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search location"
          value={searchLocation}
          onChange={handleSearch}
          className="w-full py-4 px-14 pr-20 border border-borderborder rounded-full bg-background outline-primary placeholder:font-extralight font-extralight text-sm appearance-none"
        />
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none ml-2"
        >
          <g clipPath="url(#clip0_972_5167)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.62694 1.83301C8.38449 1.83311 7.16007 2.13034 6.05584 2.69989C4.95162 3.26945 3.99961 4.09481 3.27924 5.10712C2.55887 6.11942 2.09103 7.28933 1.91476 8.51921C1.73848 9.7491 1.85888 11.0033 2.2659 12.1772C2.67293 13.3511 3.35478 14.4106 4.25458 15.2674C5.15437 16.1242 6.246 16.7534 7.43841 17.1025C8.63082 17.4516 9.88942 17.5104 11.1092 17.2742C12.329 17.0379 13.4746 16.5134 14.4504 15.7443L17.7981 19.092C17.971 19.259 18.2025 19.3514 18.4429 19.3493C18.6832 19.3472 18.9132 19.2508 19.0831 19.0808C19.2531 18.9109 19.3495 18.681 19.3516 18.4406C19.3536 18.2003 19.2613 17.9687 19.0943 17.7958L15.7466 14.4482C16.6523 13.2992 17.2162 11.9185 17.3738 10.4641C17.5314 9.00964 17.2763 7.54019 16.6378 6.22393C15.9993 4.90766 15.0031 3.79775 13.7632 3.02122C12.5234 2.24469 11.0899 1.83291 9.62694 1.83301ZM3.66861 9.62467C3.66861 8.04443 4.29636 6.5289 5.41376 5.4115C6.53117 4.29409 8.04669 3.66634 9.62694 3.66634C11.2072 3.66634 12.7227 4.29409 13.8401 5.4115C14.9575 6.5289 15.5853 8.04443 15.5853 9.62467C15.5853 11.2049 14.9575 12.7204 13.8401 13.8379C12.7227 14.9553 11.2072 15.583 9.62694 15.583C8.04669 15.583 6.53117 14.9553 5.41376 13.8379C4.29636 12.7204 3.66861 11.2049 3.66861 9.62467Z"
              fill="#A49F9F"
            />
          </g>
          <defs>
            <clipPath id="clip0_972_5167">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <button
          onClick={handleDone}
          className="rounded-full absolute right-2 top-1/2 transform -translate-y-1/2 text-primary border-primary border-2 py-1 px-4 mr-2"
        >
          {showSelectedOnly ? "Show All" : "Done"}
        </button>
      </div>

      <div className="mb-2 flex items-center">
        <input
          type="checkbox"
          id="select-all"
          onChange={handleSelectAll}
          checked={addresses.length > 0 && Object.values(checkedAddresses).every((checked) => checked)}
          className="mr-2 bg-background"
        />
        <label htmlFor="select-all" className="text-lg p-2">
          Select All
        </label>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 border-transparent">
        {displayedAddresses.map((address) => (
          <div
            key={address.id}
            className="border-transparent py-4 pr-4 rounded-md bg-background min-h-[150px] flex items-start"
          >
            <input
              type="checkbox"
              checked={checkedAddresses[address.id] || false}
              onChange={() => handleCheckboxChange(address.id)}
              className="mr-4"
            />
            <p className="border-2 border-gray-300 p-4 h-[150px] overflow-auto rounded-xl">
              {address.address}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddAddressPage;
