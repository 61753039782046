import React from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";

export default function RolesCreate() {
  const userrole = localStorage.getItem("userrole");
  const [selectedRole, setSelectedRole] = useState(null);
  const [navigateToAssignRoles, setNavigateToAssignRoles] = useState(false);
  const handleRoleClick = (role) => {
    // Check if the selected role is "admin+"
    if (role === "Admin+") {
      role = "adminpro";
    }

    setSelectedRole(role);
    setNavigateToAssignRoles(true);
  };

  if (navigateToAssignRoles && selectedRole) {
    return <Navigate to={`/AssignRoles/${selectedRole}`} />;
  }
  return (
    <div>
      <div className="flex flex-wrap mb-8">
        {/* Row 1 */}
        {!["admin", "guest", "accountant"].includes(userrole.toLowerCase()) && (
          <div className="sm:flex flex-wrap w-full space-x-10">
            {!["admin", "guest", "accountant", "admin+", "adminpro"].includes(
              userrole.toLowerCase()
            ) && (
              <div
                className={`w-1/4 bg-role-fill-2 p-8 rounded-2xl hover:boxShadow-lg flex flex-col items-center group hover:shadow-custom-hover ${
                  selectedRole === "SuperAdmin" ? "border-4 border-primary" : ""
                }`}
                onClick={() => handleRoleClick("SuperAdmin")}
              >
                {" "}
                {/* Reduced width and height */}
                <div className="text-center mb-3">
                  {" "}
                  {/* Adjusted margin-bottom */}
                  <h3 className="text-lg font-medium">Superadmin</h3>{" "}
                  {/* Adjusted text size */}
                </div>
                <div className="img flex justify-center items-center mb-4">
                  <svg
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.5 17.1274L28.921 30.1479C28.3738 30.1526 27.8747 30.0511 27.35 29.9445C27.1531 29.9044 26.9526 29.8637 26.7446 29.8276C26.2824 29.7472 25.7885 29.6955 25.2706 29.7796C25.0133 29.8214 24.7569 29.8956 24.5 30.0097V17.1274Z"
                      stroke="#0E0E0F"
                    />
                    <path
                      d="M27.3289 1.6199C26.4329 1.5559 25.6649 2.2359 25.6009 3.1239L28.8169 3.3639L28.3182 3.32726C28.3182 3.3273 28.3182 3.32734 28.3182 3.32738C27.8319 9.94655 25.2634 12.8396 23.9213 13.9088L23.68 14.101L23.7436 14.4029C23.7635 14.4976 23.7649 14.577 23.7649 14.7239V25.5319V27.4279V37.4119C23.7649 38.2291 23.1034 38.8879 22.3129 38.8879C21.5223 38.8879 20.8609 38.2291 20.8609 37.4119V27.4279V26.9279H20.3609H18.6969H18.1969V27.4279V37.4119C18.1969 38.2291 17.5354 38.8879 16.7449 38.8879C15.9543 38.8879 15.2929 38.2291 15.2929 37.4119V27.4279V25.5319V16.8119V15.4419L14.4104 16.4898C13.3025 17.8054 12.298 19.9724 12.1175 23.5378C12.0836 24.1569 11.5605 24.6334 10.9476 24.6006C10.3335 24.5677 9.86645 24.0491 9.90016 23.4144L9.90024 23.4128C10.1503 18.395 11.9168 15.6604 13.5045 14.2225L13.5045 14.2225L13.5099 14.2175C14.2935 13.4867 15.0577 13.0611 15.618 12.8251C15.7026 12.7899 15.7907 12.7561 15.8812 12.7261C16.6962 13.741 17.9474 14.3999 19.3529 14.3999C21.4022 14.3999 23.1048 13.0064 23.6257 11.1204C24.6164 9.85053 25.7809 7.46125 26.0995 3.16083L26.0996 3.15984C26.1441 2.54248 26.6771 2.0748 27.2929 2.1186M27.3289 1.6199L27.2926 2.11858C27.2927 2.11859 27.2928 2.11859 27.2929 2.1186M27.3289 1.6199L27.2932 2.11862C27.2931 2.11862 27.293 2.11861 27.2929 2.1186M27.3289 1.6199L27.2929 2.1186"
                      stroke="#0E0E0F"
                    />
                    <path
                      d="M22.3108 9.91592C22.3108 11.5715 20.9709 12.9119 19.3468 12.9119C17.7227 12.9119 16.3828 11.5715 16.3828 9.91592C16.3828 8.26032 17.7227 6.91992 19.3468 6.91992C20.9782 6.91992 22.3108 8.25956 22.3108 9.91592Z"
                      stroke="#0E0E0F"
                    />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="flex items-end justify-center  text-subtext text-sm">
                    Full control and oversight. Can create and manage all roles
                    and permissions.
                  </h3>
                </div>
              </div>
            )}
            <div
              className={`w-1/4 bg-role-fill-2 p-8 rounded-2xl hover:boxShadow-lg flex flex-col items-center group hover:shadow-custom-hover ${
                selectedRole === "Admin+" ? "border-2 border-primary" : ""
              }`}
              onClick={() => handleRoleClick("Admin+")}
            >
              <div className="text-center mb-3">
                <h3 className="text-lg font-medium">Admin +</h3>
              </div>
              <div className="img flex justify-center items-center mb-4">
                <svg
                  width="40"
                  height="41"
                  viewBox="0 0 40 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5628 21.132C22.3566 21.132 26.2428 17.2458 26.2428 12.452C26.2428 7.65814 22.3566 3.77197 17.5628 3.77197C12.769 3.77197 8.88281 7.65814 8.88281 12.452C8.88281 17.2458 12.769 21.132 17.5628 21.132Z"
                    stroke="#0E0E0F"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M32.1933 31.4362C32.0253 31.6282 31.8493 31.8202 31.6733 32.0042C28.1213 35.7162 23.1133 38.0282 17.5693 38.0282C12.0253 38.0282 7.01731 35.7162 3.46531 32.0042C3.28931 31.8202 3.11331 31.6282 2.94531 31.4362C2.94531 26.7962 5.02531 21.8762 11.0973 20.7642C13.0493 22.2202 14.8653 22.8282 17.5773 22.8282C20.2893 22.8282 22.3453 22.2042 24.0333 20.7882C30.3213 21.9962 32.1933 27.1562 32.1933 31.4362Z"
                    stroke="#0E0E0F"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M36.3093 29.7494H33.1491V26.5893C33.1491 26.3153 32.9339 26.1001 32.66 26.1001H30.5369C30.263 26.1001 30.0477 26.3153 30.0477 26.5893V29.7494H26.8876C26.6137 29.7494 26.3984 29.9646 26.3984 30.2386V32.3616C26.3984 32.6356 26.6137 32.8508 26.8876 32.8508H30.0477V36.0109C30.0477 36.2849 30.263 36.5001 30.5369 36.5001H32.66C32.9339 36.5001 33.1491 36.2849 33.1491 36.0109V32.8508H36.3093C36.5832 32.8508 36.7984 32.6356 36.7984 32.3616V30.2386C36.7984 29.9646 36.5832 29.7494 36.3093 29.7494Z"
                    fill="#0E0E0F"
                    stroke="#0E0E0F"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
              <div className="text-center">
                <h3 className="text-subtext text-sm">
                  Manages multiple branches. Can create admins, guests and
                  accountants.
                </h3>
              </div>
            </div>
            <div
              className={`w-1/4 bg-role-fill-2 p-8 rounded-2xl hover:boxShadow-lg flex flex-col items-center group hover:shadow-custom-hover ${
                selectedRole === "Admin" ? "border-2 border-primary" : ""
              }`}
              onClick={() => handleRoleClick("Admin")}
            >
              {" "}
              {/* Reduced width and height */}
              <div className="text-center mb-3">
                {" "}
                {/* Adjusted margin-bottom */}
                <h3 className="text-lg font-medium">Admin</h3>{" "}
                {/* Adjusted text size */}
              </div>
              <div className="img flex justify-center items-center mb-4">
                <svg
                  width="40"
                  height="41"
                  viewBox="0 0 40 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 20.5C24.9706 20.5 29 16.9183 29 12.5C29 8.08172 24.9706 4.5 20 4.5C15.0294 4.5 11 8.08172 11 12.5C11 16.9183 15.0294 20.5 20 20.5Z"
                    stroke="#0E0E0F"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M35 29.7725C34.8277 29.9393 34.6472 30.1061 34.4666 30.266C30.8233 33.4912 25.6865 35.5 20 35.5C14.3135 35.5 9.1767 33.4912 5.53337 30.266C5.35284 30.1061 5.17232 29.9393 5 29.7725C5 25.741 7.13348 21.4662 13.3616 20.5C15.3638 21.7651 17.2265 22.2933 20.0082 22.2933C22.7899 22.2933 24.8988 21.7512 26.6302 20.5209C33.0799 21.5704 35 26.0538 35 29.7725Z"
                    stroke="#0E0E0F"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  />
                </svg>
              </div>
              <div className="text-center">
                <h3 className="flex items-end justify-center  text-subtext text-sm">
                  Full control and oversight. Can create and manage all roles
                  and permissions.
                </h3>
              </div>
            </div>
          </div>
        )}

        {/* Row 2 */}
        <div className="flex w-full min-h-full gap-10 mt-9">
          <div
            className={`w-1/4 bg-role-fill-2 p-8 rounded-2xl hover:boxShadow-lg flex flex-col items-center group hover:shadow-custom-hover ${
              selectedRole === "Guest" ? "border-2 border-primary" : ""
            }`}
            onClick={() => handleRoleClick("Guest")}
          >
            {" "}
            {/* Reduced width and height */}
            <div className="text-center mb-3">
              {" "}
              {/* Adjusted margin-bottom */}
              <h3 className="text-lg font-medium">Guest</h3>{" "}
              {/* Adjusted text size */}
            </div>
            <div className="img flex justify-center items-center mb-4">
              <svg
                width="40"
                height="41"
                viewBox="0 0 40 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.0786 36.3082C14.0146 35.9642 14.0066 35.6042 14.0066 35.2602C14.0066 34.5242 14.0066 33.7802 14.0066 33.0442C14.0066 31.5642 14.0066 30.0922 14.0066 28.6122C14.0066 25.1642 14.0066 21.7242 14.0066 18.2762C13.2226 19.3962 12.4386 20.5162 11.6546 21.6362C11.5106 21.8442 11.3586 22.0522 11.1506 22.1962C10.9186 22.3482 10.6306 22.3962 10.3586 22.3722C9.83057 22.3242 9.34257 21.9882 9.09457 21.5242C8.75857 20.8682 8.98257 20.2442 9.35057 19.6682C10.7186 17.5402 12.1186 15.4202 13.3906 13.2282C14.1026 12.0042 14.9906 10.9962 16.3666 10.4842C17.7986 9.95622 19.4786 10.1082 20.7586 10.9322C22.1506 11.8202 23.0386 13.3082 23.8546 14.7082C24.8066 16.3322 25.8226 17.9242 26.8946 19.4762C27.4466 20.2762 27.8946 21.4362 27.0306 22.2282C25.6226 23.5162 24.3106 20.8922 23.6946 20.0522C23.2306 19.4122 22.7666 18.7722 22.3026 18.1322C22.3026 20.9082 22.3026 23.6922 22.3026 26.4682C22.3026 29.4202 22.3026 32.3802 22.3186 35.3322C22.3186 35.8602 22.3186 36.3962 22.1346 36.8922C21.9186 37.4602 21.3266 37.9802 20.6786 37.9482C20.0146 37.9082 19.4706 37.3082 19.2386 36.7322C18.9986 36.1482 18.9506 35.5002 18.9186 34.8682C18.7826 32.6122 18.6386 30.3482 18.4866 28.0922C18.4146 27.0522 18.3426 26.0122 18.2626 24.9642C17.9666 28.3802 17.6066 31.8042 17.4146 35.2282C17.3586 36.1802 17.1426 37.0602 16.2626 37.5722C15.9506 37.7562 15.5746 37.8602 15.2226 37.7722C14.5666 37.6042 14.1906 36.9082 14.0786 36.3082Z"
                  stroke="#0E0E0F"
                  stroke-miterlimit="10"
                />
                <path
                  d="M28.8607 37.2279H26.0607C24.8207 37.2279 23.8047 36.2119 23.8047 34.9719V29.6759C23.8047 28.4359 24.8207 27.4199 26.0607 27.4199H28.8607C30.1007 27.4199 31.1167 28.4359 31.1167 29.6759V34.9719C31.1087 36.2119 30.1007 37.2279 28.8607 37.2279Z"
                  stroke="#0E0E0F"
                  stroke-miterlimit="10"
                />
                <path
                  d="M26.0469 27.4282V22.7802C26.0469 22.7802 27.4629 21.5642 28.8789 22.7802V27.4282"
                  stroke="#0E0E0F"
                  stroke-miterlimit="10"
                />
                <path
                  d="M18.2338 9.21994C20.2441 9.21994 21.8738 7.59026 21.8738 5.57994C21.8738 3.56963 20.2441 1.93994 18.2338 1.93994C16.2234 1.93994 14.5938 3.56963 14.5938 5.57994C14.5938 7.59026 16.2234 9.21994 18.2338 9.21994Z"
                  stroke="#0E0E0F"
                  stroke-miterlimit="10"
                />
                <path
                  d="M26.5969 35.7557C26.4129 35.7877 26.2369 35.6677 26.2049 35.4837L25.3489 30.5637C25.3169 30.3797 25.4369 30.2037 25.6209 30.1717C25.8049 30.1397 25.9809 30.2597 26.0129 30.4437L26.8689 35.3637C26.9009 35.5477 26.7809 35.7237 26.5969 35.7557Z"
                  fill="#0E0E0F"
                />
                <path
                  d="M28.3262 35.7557C28.5102 35.7877 28.6862 35.6677 28.7182 35.4837L29.5742 30.5637C29.6062 30.3797 29.4862 30.2037 29.3022 30.1717C29.1182 30.1397 28.9422 30.2597 28.9102 30.4437L28.0542 35.3637C28.0142 35.5477 28.1422 35.7237 28.3262 35.7557Z"
                  fill="#0E0E0F"
                />
                <path
                  d="M27.461 35.7958C27.277 35.7958 27.125 35.6438 27.125 35.4598V30.4678C27.125 30.2838 27.277 30.1318 27.461 30.1318C27.645 30.1318 27.797 30.2838 27.797 30.4678V35.4598C27.797 35.6438 27.645 35.7958 27.461 35.7958Z"
                  fill="#0E0E0F"
                />
              </svg>
            </div>
            <div className="mb-3 text-center">
              <h3 className="text-subtext text-sm">
                Limited access. Can operate assigned ACs with their phones.
              </h3>
            </div>
          </div>
          <div
            className={`w-1/4 bg-role-fill-2 p-8 rounded-2xl hover:boxShadow-lg flex flex-col items-center group hover:shadow-custom-hover ${
              selectedRole === "Accountant" ? "border-2 border-primary" : ""
            }`}
            onClick={() => handleRoleClick("Accoutant")}
          >
            {" "}
            {/* Reduced width and height */}
            <div className="text-center mb-4">
              {" "}
              {/* Adjusted margin-bottom */}
              <h3 className="text-lg font-medium">Accountant</h3>{" "}
              {/* Adjusted text size */}
            </div>
            <div className="img flex justify-center items-center mb-4">
              <svg
                width="40"
                height="41"
                viewBox="0 0 40 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4874 21.692C17.8718 21.692 20.6154 18.9484 20.6154 15.564C20.6154 12.1796 17.8718 9.43604 14.4874 9.43604C11.103 9.43604 8.35938 12.1796 8.35938 15.564C8.35938 18.9484 11.103 21.692 14.4874 21.692Z"
                  stroke="#0E0E0F"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                />
                <path
                  d="M24.8123 29.764C24.6923 29.9 24.5723 30.036 24.4443 30.164C21.9323 32.788 18.3963 34.42 14.4843 34.42C10.5723 34.42 7.03625 32.78 4.52425 30.164C4.39625 30.036 4.27625 29.9 4.15625 29.764C4.15625 26.484 5.62025 23.012 9.90825 22.228C11.2843 23.252 12.5723 23.684 14.4843 23.684C16.3963 23.684 17.8523 23.244 19.0443 22.244C23.4923 23.1 24.8123 26.748 24.8123 29.764Z"
                  stroke="#0E0E0F"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                />
                <path
                  d="M38.838 17.1641H25.9531V31.7H38.838V17.1641Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M37.6794 18.2383H26.9609V21.5927H37.6794V18.2383Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M28.9614 22.6411H26.9609V24.6416H28.9614V22.6411Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M31.8286 22.6411H29.8281V24.6416H31.8286V22.6411Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M28.9614 25.5498H26.9609V27.5502H28.9614V25.5498Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M31.8286 25.5498H29.8281V27.5502H31.8286V25.5498Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M34.8208 22.6411H32.8203V24.6416H34.8208V22.6411Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M37.6879 22.6411H35.6875V24.6416H37.6879V22.6411Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M28.9614 28.459H26.9609V30.4594H28.9614V28.459Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M31.8286 28.459H29.8281V30.4594H31.8286V28.459Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M34.8208 25.5234H32.8203V27.5239H34.8208V25.5234Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M34.8208 28.4326H32.8203V30.4331H34.8208V28.4326Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.522 30.6165H35.9932C35.8273 30.6165 35.6875 30.4767 35.6875 30.3107V25.6285C35.6875 25.4625 35.8273 25.3228 35.9932 25.3228H37.522C37.6879 25.3228 37.8277 25.4625 37.8277 25.6285V30.3107C37.8277 30.4855 37.6879 30.6165 37.522 30.6165Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
                <path
                  d="M32.4918 12.5962V12.8762H32.8677V13.2202H32.4677C32.4277 13.4602 32.3318 13.6282 32.1718 13.7322C32.0198 13.8362 31.7797 13.8922 31.4677 13.8922V14.0922C31.4677 14.2122 31.4917 14.3002 31.5397 14.3722C31.5877 14.4362 31.6677 14.4762 31.7877 14.4762C31.8997 14.4762 31.9797 14.4442 32.0277 14.3802C32.0757 14.3162 32.0997 14.2202 32.0997 14.1002H32.5877C32.5797 14.3482 32.5078 14.5482 32.3718 14.6762C32.2358 14.8122 32.0357 14.8762 31.7877 14.8762C31.5237 14.8762 31.3237 14.8042 31.1797 14.6602C31.0437 14.5162 30.9718 14.3082 30.9718 14.0362V13.5402C31.2358 13.5402 31.4358 13.5322 31.5637 13.5162C31.6917 13.5002 31.7878 13.4762 31.8438 13.4282C31.9078 13.3802 31.9477 13.3082 31.9638 13.2122H30.8438V12.8682H31.9957V12.5882H30.8597V12.2202H32.8677V12.5882H32.4918V12.5962Z"
                  fill="#0E0E0F"
                />
                <path
                  d="M31.8454 16.2122C33.3564 16.2122 34.5814 14.9873 34.5814 13.4762C34.5814 11.9652 33.3564 10.7402 31.8454 10.7402C30.3343 10.7402 29.1094 11.9652 29.1094 13.4762C29.1094 14.9873 30.3343 16.2122 31.8454 16.2122Z"
                  stroke="#0E0E0F"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                />
              </svg>
            </div>
            <div className="mb-3 text-center">
              <h3 className="text-subtext text-sm">
                Responsible for accounts and banking operations.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
